import { useI18n } from "vue-i18n";
export const usePublicSiteUrl = (path) => {
  const { locale } = useI18n();

  return computed(() => {
    const baseUrl =
      locale.value === "en"
        ? "https://pinotqr.com"
        : `https://pinotqr.com/${locale.value}`;

    return `${baseUrl}/${path}`;
  });
};
